import React, { FunctionComponent, memo } from "react";

import { IProps } from "./box-subheading.types";
import "./box-subheading.scss";
/**
 * Box subtitle.
 * @param props.children Subtitle text.
 * @returns              Box subtitle.
 */
const BoxSubheading: FunctionComponent<IProps> = ({ children }) => {
    return <div className="box-subheading">{children}</div>;
};

export default memo(BoxSubheading);
