import { IActionsUnion } from "redux/types";

import emailActions from "./email-actions";
import EmailType from "./email-type";
import { IEmailState } from "./email.types";

type IEmailAction = IActionsUnion<typeof emailActions>;

// Initially an agent will have no emails and the queue is assumed to not be empty until the backend says otherwise.
export const initialState: IEmailState = {
    emails: { blank: null, query: null },
    enabled: false,
    isQueueEmpty: false,
};

/**
 * Update the email state.
 * @param state  Email state.
 * @param action Redux action.
 * @returns      Updated email state.
 */
export default function reducer(state: IEmailState = initialState, action: IEmailAction): IEmailState {
    switch (action.type) {
        case EmailType.Remove:
            return { ...state, emails: { ...state.emails, [action.payload.type]: null } };

        // Store an email.
        case EmailType.Store:
            return {
                ...state,
                emails: {
                    ...state.emails,
                    [action.payload.type]: {
                        ...action.payload.email,
                        createdAt: new Date(),
                        handlingTimes: [],
                        isExpired: false,
                        isExpiring: false,
                        isExpiringSoon: false,
                        isUnread: true,
                    },
                },
            };

        // Enable or disable email.
        case EmailType.Toggle:
            return {
                ...state,
                enabled: action.payload,
            };

        // Update the empty state of the backend queue.
        case EmailType.ToggleQueueEmpty:
            return {
                ...state,
                isQueueEmpty: action.payload,
            };

        // Update part of an email.
        case EmailType.Update:
            if (state.emails[action.payload.type] === null) {
                return state;
            } else {
                return {
                    ...state,
                    emails: {
                        ...state.emails,
                        [action.payload.type]: {
                            ...state.emails[action.payload.type],
                            ...action.payload.email,
                        },
                    },
                };
            }

        case EmailType.StoreWrapUpDraft:
            if (state.emails[action.payload.type] === null) {
                return state;
            }
            return {
                ...state,
                emails: {
                    ...state.emails,
                    [action.payload.type]: {
                        ...state.emails[action.payload.type],
                        wrapUpFormDraft: action.payload.formValues,
                    },
                },
            };

        default:
            return state;
    }
}
