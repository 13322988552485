import React, { useMemo, useContext } from "react";
import classNames from "classnames";
import { getLanguageName } from "@edgetier/utilities";
import { Tooltip } from "@edgetier/components";

import ToggleTranslationContext from "../toggle-translation-context";

import LanguageLabel from "./language-label";
import { IProps } from "./toggle-translation-button.types";

import "./toggle-translation-button.scss";
/* eslint-disable react-hooks/exhaustive-deps */

/**
 * Control to switch between an original and translated text. There can be any number of translations for a given
 * element. The languages are sorted so that the user's preferred language appears first.
 * @param props.isDisabled      Whether to disable the language toggle or not.
 * @param props.tooltipPosition Where to position the language tooltips.
 * @returns                     Language toggle controls.
 */
const ToggleTranslationButton = ({ isDisabled }: IProps) => {
    const { languageId, languageIds, languages, isTranslated, preferredLanguageId, setLanguageId } = useContext(
        ToggleTranslationContext
    );
    const memoisedButtons = useMemo(
        () => (
            <ul className="toggle-translation__button">
                {languageIds
                    .slice()
                    .sort((one, two) =>
                        one === preferredLanguageId ? -1 : two === preferredLanguageId ? 1 : one - two
                    )
                    .map((itemId, index) => (
                        <Tooltip
                            content={<>{getLanguageName(languages, itemId)}</>}
                            key={itemId}
                            possiblePlacements={["top-center", "bottom-center"]}
                        >
                            <li
                                className={classNames({
                                    "toggle-translation__button--inactive": itemId !== languageId,
                                    "toggle-translation__button--active": itemId === languageId,
                                    "toggle-translation__button--disabled": isDisabled === true && index > 0,
                                })}
                                onClick={setLanguageId.bind(null, itemId)}
                            >
                                <LanguageLabel languageId={itemId} languages={languages} />
                            </li>
                        </Tooltip>
                    ))}
            </ul>
        ),
        [languageId, languageIds, isTranslated, preferredLanguageId, isDisabled]
    );
    return memoisedButtons;
};

export default ToggleTranslationButton;
