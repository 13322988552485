import classNames from "classnames";
import React from "react";

import { IProps } from "./character-count.types";
import { THRESHOLD } from "./character-count.constants";

/**
 * Display a character count if an input has a limit.
 * @param props Props containing the field's input length and maximum allowed length.
 * @returns     Null if the character length is not beyond the allowed limit or a character indicator otherwise.
 */
export default ({ length, maximumLength }: IProps) =>
    length >= maximumLength * THRESHOLD ? (
        <div
            className={classNames("field__character-count", {
                "field__character-count--limit": length === maximumLength,
            })}
        >
            {length}/{maximumLength}
        </div>
    ) : null;
