import React from "react";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Unchecked radio button icon.
 * @returns Icon.
 */
const UncheckedIcon = () => (
    <FontAwesomeIcon aria-label="radio-unchecked-icon" fixedWidth={true} icon={faCircle} size="lg" />
);

export default UncheckedIcon;
