import React from "react";
import { VariableType } from "@edgetier/types";
import { intersectWithEnum } from "@edgetier/utilities";

import DetailsField from "./details-field";
import { IProps } from "./details-fields.types";
import "./details-fields.scss";

/**
 * Display various details associated with an email or something else. This is a format returned by the backend to
 * flexibly display miscellaneous information. Fields with an unrecognised variable type are excluded because something
 * may break in future with new types.
 * @param fields Data to display containing one or more fields.
 * @returns      Table of information.
 */
const DetailsFields = ({ fields }: IProps) => (
    <div className="details-fields">
        <table>
            <tbody>
                {intersectWithEnum(VariableType, fields, ({ variableTypeId }) => variableTypeId).map((field) => (
                    <DetailsField key={`${field.title}-${field.title}`} field={field} />
                ))}
            </tbody>
        </table>
    </div>
);

export default DetailsFields;
