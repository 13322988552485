import React from "react";

import { IProps } from "./array-details-section.types";
import "./array-details-section.scss";

import TooltipWarning from "~/tooltip-warning";
import { AccordionItem, AccordionItemHeader, AccordionItemContent } from "~/accordion";
import DetailsFields from "~/details/details-fields";

/**
 * Within the booking details the backend can define a number of fields to display. This one is part of an array of
 * fields that can be expanded or collapsed like an accordion.
 * @param props.section Title and fields of the collapsible section to display.
 * @returns             Section that can be opened or closed.
 */
const ArrayDetailsSection = ({ section }: IProps) => (
    <div className="array-details-section">
        <AccordionItem canOpen={Array.isArray(section.fields) && section.fields.length > 0} isOpen={section.isOpen}>
            <AccordionItemHeader>
                {section.title}
                {section.fields.some(({ warning }) => typeof warning === "string") && (
                    <TooltipWarning warning="Expand for warning" />
                )}
            </AccordionItemHeader>

            <AccordionItemContent>
                <DetailsFields fields={section.fields} />
            </AccordionItemContent>
        </AccordionItem>
    </div>
);

export default ArrayDetailsSection;
