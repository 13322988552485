import LoadingCell from "../loading-cell";
import React from "react";
import RowContext from "../row-context";
import { IProps } from "./button-cell.types";
import "./button-cell.scss";

/**
 * Table cell containing a button. This is styled differently than normal cells.
 * @param props.children Contents of the button cell.
 * @returns              Table cell component.
 */
const ButtonCell = <T extends {}>({ children }: IProps<T>) => (
    <RowContext.Consumer>
        {({ row }) => <td className="grid__button-cell">{row === null ? <LoadingCell /> : children(row)}</td>}
    </RowContext.Consumer>
);

export default ButtonCell;
