import classNames from "classnames";
import { FunctionComponent, memo } from "react";
import { useSelector } from "react-redux";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IApplicationState } from "redux/types";
import { IProps } from "./chat-link-indicators.types";

/**
 * Link to the chat screen. Its appearance will change depending on if there are unread messages or not.
 */
const ChatLinkIndicators: FunctionComponent<IProps> = () => {
    const { hasInvites, isChatScreenOpen, messageCount, unreadMessageCount } = useSelector<
        IApplicationState,
        { hasInvites: boolean; isChatScreenOpen: boolean; messageCount: number; unreadMessageCount: number }
    >(({ chat }) => ({
        hasInvites: Object.keys(chat.invites).length > 0,
        isChatScreenOpen: chat.isChatScreenOpen,
        messageCount: Object.values(chat.chats).reduce((total, { messages }) => total + messages.length, 0),
        unreadMessageCount: Object.values(chat.chats).reduce((total, { unreadMessages }) => total + unreadMessages, 0),
    }));

    const readMessageCount = messageCount - unreadMessageCount;
    const unreadMessages = messageCount > readMessageCount;

    return (
        <div
            className={classNames("navigation__chat-link", {
                "navigation__chat-link--highlight": !isChatScreenOpen && (unreadMessages || hasInvites),
            })}
        >
            {hasInvites && !isChatScreenOpen && (
                <div className="navigation__chat-link-invites">
                    <FontAwesomeIcon className="navigation__chat-link-invites__icon" icon={faUserPlus} />
                </div>
            )}
            {unreadMessages && !isChatScreenOpen && (
                <div className="navigation__chat-link-messages">{messageCount - readMessageCount}</div>
            )}
        </div>
    );
};

export default memo(ChatLinkIndicators);
