import ChatLinkIndicators from "./chat-link-indicators";
import "./chat-link.scss";
import { NavigationLink } from "@edgetier/client-components";
import { faComments } from "@fortawesome/pro-solid-svg-icons";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Link to the chat screen. This shows unread message and invite indicators.
 * @returns Link to the chat screen.
 */
const ChatLink = () => (
    <NavigationLink label="Chat" icon={faComments} page={AbsolutePaths[PageNames.Chat]()}>
        <ChatLinkIndicators />
    </NavigationLink>
);

export default ChatLink;
