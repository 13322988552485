import React from "react";

import { IProps } from "./toggle-disabled.types";
import "./toggle-disabled.scss";

/**
 * Label for a toggle control when the control is disabled.
 * @param props.children Label.
 * @returns              Label text.
 */
const ToggleDisabled = ({ children }: IProps) => <span className="toggle__state--disabled">{children}</span>;

export default ToggleDisabled;
