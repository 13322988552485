import React, { useCallback, useEffect, useRef } from "react";
import { connect } from "formik";
import { debounce } from "throttle-debounce";

import { DEBOUNCE_MILLISECONDS } from "./submit-on-change.constants";
import { IProps } from "./submit-on-change.types";

/**
 * Submit a form when the values change.
 * @param delay      Debounce delay to stop calling submit too often.
 * @param submitForm Formik handler to submit the form.
 * @param values     Current form values. When these change the form will trigger a submission.
 * @returns          A component that doesn't render anything.
 */
const SubmitOnChange = ({ delay = DEBOUNCE_MILLISECONDS, submitForm, values }: IProps) => {
    // Debounce the submission to stop it happening too often.
    const submitFormDebounced = useCallback(debounce(delay, false, submitForm), []);

    // The submission should only happen on updates, not when mounted.
    const isInitialMount = useRef(true);

    // Ignoring the initial mount, trigger a submission any time the values on the form change.
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            submitFormDebounced();
        }
    }, [submitFormDebounced, values]);

    return null;
};

// Connect the component to Formik to retrieve the form values and submission function.
export default connect<Partial<Omit<IProps, "submitForm" | "values">>>(({ delay, formik }) => (
    <SubmitOnChange delay={delay} submitForm={formik.submitForm} values={formik.values} />
));
