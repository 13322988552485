import React from "react";
import { IProps } from "./header.types";
import "./header.scss";

/**
 * Content above a table e.g. filters, buttons, pagination etc.
 * @param props.children Header content.
 * @returns              Header component.
 */
const Header = ({ children }: IProps) => <div className="grid__header">{children}</div>;

export default Header;
