import React, { FunctionComponent, memo } from "react";
import { EllipsisMenu } from "~/index";
import { IProps } from "./metric-box-actions-menu.types";
import "./metric-box-actions-menu.scss";

/**
 * A dropdown options menu for metrics
 * @param props.children        Children element
 */
const MetricBoxActionsMenu: FunctionComponent<IProps> = ({ children }) => {
    return (
        <div className="metric-box-actions-menu">
            <EllipsisMenu placement="bottom-end" overflowContainer>
                {children}
            </EllipsisMenu>
        </div>
    );
};

export default memo(MetricBoxActionsMenu);
