import {
    EnumLabel,
    DetailsFieldTitle,
    DetailsFieldValue,
    DetailsSection,
    DetailsSectionTitle,
} from "@edgetier/client-components";
import Qs from "qs";

import HandlingType from "constants/handling-type";
import { ampersandList } from "@edgetier/client-components";

import ChatAndCallDates from "./chat-and-call-dates";
import ChatBrandWidgetFields from "./chat-brand-widget-fields";
import ChatDeflectionFields from "./chat-deflection-fields";
import EmailInDates from "./email-in-dates";
import EmailOutDates from "./email-out-dates";
import TotalHandlingTime from "./total-handling-time";
import BrandDetailsField from "./brand-details-field";
import { IProps } from "./common-details.types";
import { useMemo } from "react";
import "./common-details.scss";
import ChatVariableFields from "./chat-variable-fields";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Display details common to chats, calls and emails in the interaction search screen.
 * @param props Props containing the date, disposition codes, user etc.
 * @returns     Details of the email, call or chat.
 */
const CommonDetails = ({
    agentFinishTime = null,
    brandId = null,
    chatId = null,
    conversationKey,
    date,
    handlingTypeId = null,
    interactionId,
    interactionDetailId,
    isCall = false,
    isChat = false,
    isEmailIn = false,
    language,
    queryId,
    setupId = null,
    skills,
    totalHandlingTime = null,
    user = null,
}: IProps) => {
    const skillsString = useMemo(() => ampersandList(skills.map(({ skill }) => skill)), [skills]);
    const interactionUrl = useMemo(
        () =>
            `${window.location.host}${AbsolutePaths[PageNames.QueryHistory]()}${Qs.stringify(
                { queryId, interactionId },
                { addQueryPrefix: true }
            )}`,
        [interactionId, queryId]
    );
    return (
        <DetailsSection>
            <DetailsSectionTitle title="Details" />
            <table>
                <tbody>
                    {isChat || isCall ? (
                        <ChatAndCallDates agentFinishTime={agentFinishTime} date={date} />
                    ) : isEmailIn ? (
                        <EmailInDates agentFinishTime={agentFinishTime} date={date} />
                    ) : (
                        <EmailOutDates date={date} />
                    )}

                    <tr>
                        <td>
                            <DetailsFieldTitle>Language</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue copyText={language}>{language}</DetailsFieldValue>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <DetailsFieldTitle>Conversation Key</DetailsFieldTitle>
                        </td>
                        <td>
                            <DetailsFieldValue copyText={conversationKey}>{conversationKey}</DetailsFieldValue>
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <DetailsFieldTitle>Interaction URL</DetailsFieldTitle>
                        </td>
                        <td className="common-details__interaction-url">
                            <DetailsFieldValue copyText={interactionUrl}>{interactionUrl}</DetailsFieldValue>
                        </td>
                    </tr>

                    {user !== null && (
                        <tr>
                            <td>
                                <DetailsFieldTitle>Agent</DetailsFieldTitle>
                            </td>
                            <td>
                                <DetailsFieldValue copyText={user}>{user}</DetailsFieldValue>
                            </td>
                        </tr>
                    )}

                    {typeof handlingTypeId === "number" && handlingTypeId in HandlingType && (
                        <tr>
                            <td>
                                <DetailsFieldTitle>Handling Type</DetailsFieldTitle>
                            </td>
                            <td>
                                <EnumLabel>{HandlingType[handlingTypeId]}</EnumLabel>
                            </td>
                        </tr>
                    )}

                    {typeof totalHandlingTime === "number" && (
                        <tr>
                            <td>
                                <DetailsFieldTitle>Handling Time</DetailsFieldTitle>
                            </td>
                            <td>
                                <TotalHandlingTime totalHandlingTime={totalHandlingTime} />
                            </td>
                        </tr>
                    )}

                    {skills.length > 0 && (
                        <tr>
                            <td>
                                <DetailsFieldTitle>Skill</DetailsFieldTitle>
                            </td>
                            <td>
                                <DetailsFieldValue copyText={skillsString}>{skillsString}</DetailsFieldValue>
                            </td>
                        </tr>
                    )}

                    {typeof brandId === "number" && <BrandDetailsField brandId={brandId} />}

                    {isChat && typeof chatId === "number" && <ChatDeflectionFields chatId={chatId} />}
                    {isChat && typeof setupId === "number" && <ChatBrandWidgetFields setupId={setupId} />}
                    {isChat && <ChatVariableFields interactionDetailId={interactionDetailId} />}
                </tbody>
            </table>
        </DetailsSection>
    );
};

export default CommonDetails;
