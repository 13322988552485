import React, { FunctionComponent, memo } from "react";
import classNames from "classnames";

import { IProps } from "./page.types";
import "./page.scss";

/**
 * Wrapper for a application page that will apply some styling.
 * @param props.children  Page content.
 * @param props.className Optional class name(s) to add to the page.
 * @returns               Wrapper around page.
 */
const Page: FunctionComponent<IProps> = ({ children, className }) => {
    return <div className={classNames("page", className)}>{children}</div>;
};

export default memo(Page);
