import React from "react";

import { IProps } from "./details-sections.types";

import { DetailsSection, DetailsSectionTitle } from "~/details";
import DetailsFields from "~/details/details-fields";

/**
 * Display various details associated with an email or something else. This is a format returned by the backend to
 * flexibly display miscellaneous information.
 * @param sections Array of sections to display. Each one contains one or more fields.
 * @returns        Sections displayed with titles and tables.
 */
const DetailsSections = ({ sections }: IProps) => (
    <div className="details-sections">
        {Array.isArray(sections) &&
            sections.map((section) => (
                <DetailsSection key={section.title}>
                    <DetailsSectionTitle title={section.title} />
                    <DetailsFields key={section.title} fields={section.fields} />
                </DetailsSection>
            ))}
    </div>
);

export default DetailsSections;
