import LoadingCell from "../loading-cell";
import React, { useContext } from "react";
import RowContext from "../row-context";
import { IProps } from "./cell.types";
import { NavLink } from "react-router-dom";
import GridContext from "~/grid/grid-context";
import classNames from "classnames";
import "./cell.scss";

/**
 * Render a table cell with the contents being determined by a child render prop pattern.
 * @param props.children   Cell contents.
 * @param props.excludeRowLink Specific cell behaviour to include or not the row link when exists
 * @param props.otherProps Props to pass to the table cell.
 * @returns                Table cell component.
 */
const Cell = <T extends {}>({ children, className, excludeRowLink = false, ...otherProps }: IProps<T>) => {
    const { getRowLink } = useContext(GridContext);

    return (
        <RowContext.Consumer>
            {({ row }) => (
                <td
                    className={classNames("grid__cell", className, {
                        "grid__cell--has-link": typeof getRowLink === "function",
                    })}
                    {...otherProps}
                >
                    {row === null ? (
                        <LoadingCell />
                    ) : (
                        <>
                            {typeof getRowLink === "function" &&
                            typeof getRowLink(row) !== "undefined" &&
                            !excludeRowLink ? (
                                <NavLink className="grid__cell__link" to={getRowLink(row)}>
                                    {children(row)}
                                </NavLink>
                            ) : (
                                <>{children(row)}</>
                            )}
                        </>
                    )}
                </td>
            )}
        </RowContext.Consumer>
    );
};

export default Cell;
