import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId } from "@edgetier/utilities";

import { IProps } from "./numeric-form-field.types";

/**
 * Render a numeric field (integer, float etc.).
 * @param props.formField   Numeric form field.
 * @param props.translation Text elements in the user's language.
 * @param props.inputProps  Any other props to pass to the input element.
 * @returns                 Numeric input.
 */
const NumericFormField = ({ formField, translation, ...inputProps }: IProps) => (
    <Field name={formField.fieldName}>
        {({ field, form }: FieldProps) => (
            <input
                {...inputProps}
                {...field}
                aria-describedby={createFieldErrorId(field.name)}
                aria-invalid={typeof form.errors[field.name] === "string"}
                aria-required={formField.required}
                autoComplete="off"
                id={formField.fieldName}
                name={field.name}
                placeholder={translation.placeholderText || ""}
                type="number"
            />
        )}
    </Field>
);

export default NumericFormField;
