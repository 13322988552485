import classNames from "classnames";
import React, { FunctionComponent, memo, useEffect, useMemo, useRef, useState } from "react";
import { faBan } from "@fortawesome/free-solid-svg-icons/faBan";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { IAttachment } from "@edgetier/types";
import { FileIcon, Tooltip } from "@edgetier/components";
import Axios from "axios";

import { IProps } from "./attachment-button.types";

const AttachmentButton: FunctionComponent<IProps> = ({
    attachment,
    canDelete,
    children: deleteButton,
    deleted,
    getAttachment,
    onBlocked,
    onServerError,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const fileName = useMemo(() => attachment.attachment, [attachment]);

    const attachmentTextRef = useRef<HTMLDivElement>(null);
    const [isOverflowed, setIsOverflowed] = useState(false);

    useEffect(() => {
        const attachmentText = attachmentTextRef.current;
        if (attachmentText !== null) {
            setIsOverflowed(attachmentText.scrollWidth > attachmentText.clientWidth);
        }
    }, []);

    /**
     * Open an attachment when clicked. The backend will return a secure URL where the attachment can be found.
     * @param attachment The attachment to open.
     */
    const onClick = async (attachment: IAttachment): Promise<void> => {
        // Prevent clicking while the attachment is already being requested.
        if (isLoading) {
            return;
        }

        // Show a loading spinner while the attachment is being retrieved.
        setIsLoading(true);

        // Prepend relative URLs with a forward slash.
        const url = attachment.url.replace(/^([a-z])/i, "/$1");

        try {
            const response = await getAttachment(url);
            if (typeof response.data === "string" && attachment.attachment !== null) {
                // Positioning for dual monitors.
                const dualScreenLeft = typeof window.screenLeft !== "undefined" ? window.screenLeft : window.screenX;
                const dualScreenTop = typeof window.screenTop !== "undefined" ? window.screenTop : window.screenY;

                // Screen width.
                const screenWidth = window.innerWidth
                    ? window.innerWidth
                    : document.documentElement.clientWidth
                    ? document.documentElement.clientWidth
                    : window.screen.width;

                // Screen height.
                const screenHeight = window.innerHeight
                    ? window.innerHeight
                    : document.documentElement.clientHeight
                    ? document.documentElement.clientHeight
                    : window.screen.height;

                // The window will be a certain percentage of the full screen size.
                const windowWidth = screenWidth * 0.75;
                const windowHeight = screenHeight * 0.75;

                // Centre the new window.
                const left = screenWidth / 2 - windowWidth / 2 + dualScreenLeft;
                const top = screenHeight / 2 - windowHeight / 2 + dualScreenTop;

                // Open the attachment in a new window.
                const attachmentWindow = window.open(
                    response.data,
                    attachment.attachment,
                    `scrollbars=yes, width=${windowWidth}, height=${windowHeight}, top=${top}, left=${left}`
                );

                // Focus the window or display an error if it's blocked.
                if (attachmentWindow) {
                    attachmentWindow.focus();
                } else {
                    onBlocked();
                }
            }
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                onServerError(serverError);
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div
            className={classNames("attachments__item", {
                "attachments--unavailable": isLoading || deleted,
                "attachments__item--with-delete-button": canDelete || deleted,
            })}
        >
            <Tooltip
                content={<div className="attachments__item__tooltip-content">{fileName}</div>}
                disableTooltip={fileName === null || deleted || !isOverflowed}
                useArrow
            >
                <button
                    aria-label="attachment button"
                    className="attachments__item__button"
                    onClick={onClick.bind(null, attachment)}
                    type="button"
                    disabled={deleted}
                >
                    {/* Deleted attachments can have no file name */}
                    {fileName === null || deleted ? (
                        <>
                            <FontAwesomeIcon fixedWidth={true} icon={faBan} />
                            {"Deleted Attachment"}
                        </>
                    ) : (
                        <div ref={attachmentTextRef}>
                            <FileIcon fileName={fileName} isLoading={isLoading} />
                            {fileName}
                        </div>
                    )}
                </button>
            </Tooltip>

            {/* If an attachment has been deleted render an info icon that the user can hover over to see why it's been deleted 
                If the user has permission to delete and the attachment is not deleted render the delete button */}
            {deleted ? (
                <button className="attachments__item__info-icon">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                </button>
            ) : (
                canDelete &&
                deleteButton && <span className="attachments__item__delete-button">{deleteButton(attachment)}</span>
            )}
        </div>
    );
};

export default memo(AttachmentButton);
