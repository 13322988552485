import React from "react";

import { IProps } from "./link-details-field.types";

import { DetailsFieldTitle, DetailsFieldValue } from "~/details";

import "./link-details-field.scss";

/**
 * Render a clickable link value from the booking details.
 * @param props.properties Object containing link to follow.
 * @param props.value      Link text.
 * @returns                Clickable link.
 */
const LinkDetailsField = ({ field }: IProps) => (
    <tr className="link-details-field">
        <td>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
        </td>
        <td>
            <DetailsFieldValue isNull={typeof field.value !== "string"} warning={field.warning}>
                <a className="link-details-field" href={field.properties.url} rel="noopener noreferrer" target="_blank">
                    {field.value}
                </a>
            </DetailsFieldValue>
        </td>
    </tr>
);

export default LinkDetailsField;
