import classNames from "classnames";
import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId, createId } from "@edgetier/utilities";

import SelectOptions from "../select-options";

import { IProps } from "./buttons-form-field.types";

/**
 * Buttons field type for dynamic forms. This behaves the same as a set of radio buttons, the only difference is how
 * they are displayed. It uses radio buttons behind the scenes for accessibility.
 * @param props.autoFocus  Automatically focus on the field when it renders.
 * @param props.formField  Buttons form field.
 * @param props.languageId User's language.
 * @returns                Multiple buttons.
 */
const Buttons = ({ autoFocus, formField, languageId, translation }: IProps) => (
    <Field name={formField.fieldName}>
        {({ field: { name, value }, form: { errors, setFieldValue } }: FieldProps) => (
            <div
                aria-label={translation.formFieldTranslation}
                aria-required={formField.required}
                className={classNames("dynamic-form__buttons", {
                    "dynamic-form__buttons--dirty": value.length > 0,
                })}
                role="radiogroup"
            >
                <div className="dynamic-form__buttons__container">
                    <SelectOptions languageId={languageId} selectOptions={formField.configuration.selectOptions}>
                        {({ index, label, selectOptionId }) => (
                            <div
                                className={classNames("dynamic-form__buttons__button", {
                                    "dynamic-form__buttons__button--not-selected": value[0] !== selectOptionId,
                                    "dynamic-form__buttons__button--selected": value[0] === selectOptionId,
                                })}
                                key={selectOptionId}
                            >
                                <input
                                    aria-describedby={createFieldErrorId(name)}
                                    aria-invalid={typeof errors[name] === "string"}
                                    autoFocus={autoFocus && index === 0}
                                    checked={selectOptionId === value[0]}
                                    id={createId(name, selectOptionId)}
                                    name={name}
                                    onChange={(changeEvent) =>
                                        changeEvent.target.checked && setFieldValue(name, [selectOptionId])
                                    }
                                    type="radio"
                                />
                                <label htmlFor={createId(name, selectOptionId)}>{label}</label>
                            </div>
                        )}
                    </SelectOptions>
                </div>
            </div>
        )}
    </Field>
);

export default Buttons;
