import React from "react";

import { IProps } from "./accordion.types";

/**
 * Wrapper for accordion items.
 * @param     props.children Accordion content.
 * @returns                  Accordion element.
 */
export const Accordion = ({ children }: IProps) => <div className="accordion">{children}</div>;

export default Accordion;
