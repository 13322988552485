import React from "react";
import { IProps } from "./details-field-title.types";
import LoadingSkeleton from "~/loading-skeleton";
import "./details-field-title.scss";

/**
 * Display a title above a booking details field.
 * @param children Content of the title.
 * @returns        Title component.
 */
const DetailsFieldTitle = ({ children, isLoading = false }: IProps) => (
    <div className="details-field-title">
        {isLoading ? (
            <div className="details-field-title__loading">
                <LoadingSkeleton inline />
            </div>
        ) : (
            children
        )}
    </div>
);

export default DetailsFieldTitle;
