import { FunctionComponent, memo } from "react";
import { IProps } from "./assignment-details-field.types";
import { DetailsFieldTitle, DetailsFieldValue } from "@edgetier/client-components";
import useUser from "hooks-for/users/use-user";
import { format, isAfter } from "date-fns";
import "./assignment-details-field.scss";
import classNames from "classnames";

/**
 * Show details about an email assignment.
 * @param props.assignment The assignment to be displayed.
 */
const AssignmentDetailsField: FunctionComponent<IProps> = ({ assignment, interactionCompleted }) => {
    const { data: assignedUser, isLoading: isAssignedUserLoading } = useUser(assignment.userId);
    const { data: assignedByUser, isLoading: isAssignedByUserLoading } = useUser(
        assignment.assignedByUserId ?? 0,
        {},
        { enabled: assignment.assignedByUserId !== null }
    );

    const isActive =
        !interactionCompleted &&
        (assignment.assignedUntil === null || isAfter(new Date(assignment.assignedUntil), new Date()));

    return (
        <tr>
            <td className="assignment-details-field">
                <div className="assignment-details-field__date">
                    <DetailsFieldTitle>
                        <span>
                            {`${format(new Date(assignment.createDateTime), "dd/MM/yyyy HH:mm")}`}&ndash;{" "}
                            <span
                                className={classNames("assignment-details-field__status", {
                                    "assignment-details-field__status--active": isActive,
                                })}
                            >
                                {isActive ? "Active" : "Expired"}
                            </span>
                        </span>
                    </DetailsFieldTitle>
                </div>
                <div className="assignment-details-field__text">
                    <DetailsFieldValue isLoading={isAssignedUserLoading || isAssignedByUserLoading}>
                        <span>
                            {`Assigned to ${assignedUser?.firstName} ${assignedUser?.surname}`}
                            {` by ${
                                assignment.automaticallyAssigned
                                    ? "system"
                                    : `${assignedByUser?.firstName}  ${assignedByUser?.surname}`
                            }`}
                            {` until ${
                                assignment.assignedUntil !== null
                                    ? format(new Date(assignment.assignedUntil), "dd/MM/yyyy HH:mm")
                                    : "complete"
                            }`}
                        </span>
                    </DetailsFieldValue>
                </div>
            </td>
        </tr>
    );
};

export default memo(AssignmentDetailsField);
