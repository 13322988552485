import React, { FunctionComponent, memo } from "react";

import { IProps } from "./box-heading.types";
import "./box-heading.scss";

/**
 * Box title.
 * @param props.children Title text.
 * @returns              Box title.
 */
const BoxHeading: FunctionComponent<IProps> = ({ children }) => {
    return <h3 className="box-heading">{children}</h3>;
};

export default memo(BoxHeading);
