import GridContext from "../../../grid-context";
import React from "react";
import TableRow from "../table-row";
import { DEFAULT_LOADING_ROWS } from "./loading.constants";
import { IProps } from "./loading.types";

/**
 * Show some blank rows with spinners while the results for the table are being fetched,.
 * @returns As many rows are in the page size or a default number if a page size has not been set.
 */
const Loading = ({ children }: IProps) => (
    <GridContext.Consumer>
        {({ pageSize }) =>
            Array(Number.isFinite(pageSize) ? pageSize : DEFAULT_LOADING_ROWS)
                .fill(0)
                .map((_, index) => (
                    <TableRow isFixed={false} key={index} row={null}>
                        {children}
                    </TableRow>
                ))
        }
    </GridContext.Consumer>
);

export default Loading;
