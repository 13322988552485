import LoadingCell from "../loading-cell";
import React from "react";
import RowContext from "../row-context";
import ToggleDisabled from "./toggle-disabled";
import { Fragment } from "react";
import { IProps } from "./toggle.types";
import { UniqueId } from "@edgetier/components";
import "./toggle.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

/**
 * Cell to toggle a row in the table.
 */
const Toggle = <T extends {}>({ canToggle, isActive, onToggle }: IProps<T>) => (
    <td className="grid__toggle">
        <RowContext.Consumer>
            {({ row }) =>
                row === null ? (
                    <LoadingCell />
                ) : typeof canToggle !== "function" || canToggle(row) ? (
                    <div className="checkbox checkbox-no-label">
                        <UniqueId>
                            {(uniqueId) => (
                                <Fragment>
                                    <input
                                        id={uniqueId}
                                        type="checkbox"
                                        checked={isActive(row)}
                                        onChange={() => {
                                            onToggle(row);
                                        }}
                                    />
                                    <label htmlFor={uniqueId}>
                                        <FontAwesomeIcon icon={isActive(row) ? faCheckCircle : faCircle} size="lg" />
                                    </label>
                                </Fragment>
                            )}
                        </UniqueId>
                    </div>
                ) : (
                    <ToggleDisabled />
                )
            }
        </RowContext.Consumer>
    </td>
);
export default Toggle;
