import React from "react";
import { IProps } from "./table-footer.types";
import "./table-footer.scss";

/**
 * Container for controls at the bottom of a table.
 * @param props.children Footer content.
 */
const TableFooter = ({ children }: IProps) => <div className="grid__table-footer">{children}</div>;

export default TableFooter;
