import React, { FunctionComponent, memo, useContext } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./navigation-link.types";
import "./navigation-link.scss";
import CategoryNavigationContext from "~/category-navigation/category-navigation-context/category-navigation-context";

/**
 * Link to a page with a label and icon.
 * @param props.label Link text.
 * @param props.icon  Link icon.
 * @param props.page  Click destination.
 * @returns           Clickable page link.
 */
const NavigationLink: FunctionComponent<IProps> = ({ children, label, icon, page }) => {
    const { close } = useContext(CategoryNavigationContext);
    return (
        <li className="navigation-link" aria-label={label} onClick={() => close()}>
            <NavLink to={page} className={({ isActive }) => "navigation-link__inner " + (isActive ? "active" : "")}>
                <FontAwesomeIcon fixedWidth={true} icon={icon} />
                <div>{label}</div>
                {children}
            </NavLink>
        </li>
    );
};

export default memo(NavigationLink);
