import { getTimeToRead } from "@edgetier/utilities";
import { AxiosError, AxiosInstance } from "axios";
import toast from "react-hot-toast";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";
import { IPermission, IRole, IRolePermission } from "@edgetier/types";
import urljoin from "url-join";

interface IProps {
    readonly role: IRole;
    readonly permission: IPermission;
    readonly enablePermission?: boolean;
}

/**
 * Create a request with POST for a new role or update the role with PUT
 * @param routeUrl              API route URL
 * @param axiosInstance         Axios instance specific to an application
 * @param props.role            Existing role
 * @param props.permission      Existing permission
 */
const togglePermission =
    (routeUrl: string, axiosInstance: AxiosInstance, { role, permission }: IProps) =>
    async (enablePermission: boolean): Promise<IProps> => {
        if (enablePermission) {
            const queryUrl = routeUrl.replace(":roleId", role.roleId.toString());

            // Assign permission with POST
            await axiosInstance.post(queryUrl, {
                permissionId: permission.permissionId,
            });
            return { role, permission, enablePermission };
        } else {
            const queryUrl = urljoin(
                routeUrl.replace(":roleId", role.roleId.toString()),
                permission.permissionId.toString()
            );

            // Revoke permission with DELETE
            await axiosInstance.delete(queryUrl);
            return { role, permission, enablePermission };
        }
    };

/**
 * Custom hook to create or update an existing role and updating the roles query cache.
 * @param routeUrl              API route URL
 * @param axiosInstance         Axios instance specific to an application
 * @param props.role            Existing role
 * @param props.permission      Existing permission
 * @param configuration         Optional Axios configuration
 * @returns A mutation to create or update an existing role
 */
const useTogglePermission = (
    routeUrl: string,
    axiosInstance: AxiosInstance,
    { role, permission }: IProps,
    configuration: UseMutationOptions<IProps, AxiosError, boolean> = {}
) => {
    const queryClient = useQueryClient();

    /**
     * Success callback function update the roles query cache to include the latest changes
     */
    const onSuccess = ({ role, permission, enablePermission }: IProps) => {
        const queryUrl = routeUrl.replace(":roleId", role.roleId.toString());

        if (enablePermission) {
            queryClient.setQueryData<IRolePermission>([queryUrl, role.roleId], (activePermissions) => {
                if (typeof activePermissions !== "undefined") {
                    const [roleId, permissions] = Object.entries(activePermissions)[0];
                    const result = [...permissions, permission];
                    return { [roleId]: result };
                }

                return {};
            });

            const successMessage = `Permission granted`;
            toast.success(successMessage, { duration: getTimeToRead(successMessage, 1000) });
        } else {
            queryClient.setQueryData<IRolePermission>([queryUrl, role.roleId], (activePermissions) => {
                if (typeof activePermissions !== "undefined") {
                    const [roleId, permissions] = Object.entries(activePermissions)[0];
                    const result = permissions.filter(({ permissionId }) => permissionId !== permission.permissionId);
                    return { [roleId]: result };
                }

                return {};
            });

            const successMessage = "Permission revoked";
            toast.success(successMessage, { duration: getTimeToRead(successMessage, 1000) });
        }
    };

    /**
     * Show a toast message with the request error
     */
    const onError = () => {
        toast.error("Failed to update the permission");
    };

    const mutationSettings = { onSuccess, onError, ...configuration };
    return useMutation(togglePermission(routeUrl, axiosInstance, { role, permission }), mutationSettings);
};

export default useTogglePermission;
