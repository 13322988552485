import NumericValue from "../numeric-value";
import React, { useMemo } from "react";
import { convertSeconds } from "./time-period-value.utilities";
import { IProps } from "./time-period-value.types";
import { TimeUnit } from "./time-unit";

/**
 * Convert seconds to a more sensible value.
 * @param props.seconds Seconds value before conversion.
 * @param otherProps    Props to pass to the numeric value component.
 * @returns             Value converted if necessary.
 */
const TimePeriodValue = ({ seconds, ...otherProps }: IProps) => {
    const [value, unit] = useMemo(() => convertSeconds(seconds), [seconds]);

    return (
        <NumericValue
            value={value}
            unit={unit}
            options={{ maximumFractionDigits: unit !== TimeUnit.Seconds ? 1 : 0 }}
            {...otherProps}
        />
    );
};

export default TimePeriodValue;
