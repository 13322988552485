import React, { FunctionComponent, memo } from "react";
import { format } from "date-fns";

import { IProps } from "./formatted-date.types";
import "./formatted-date.scss";

/**
 * Format a date for a user showing just the date (i.e. no time).
 * @param props.dateTime Date to render.
 * @returns              Formatted date.
 */
const FormattedDate: FunctionComponent<IProps> = ({ dateTime }) => {
    return <div className="formatted-date">{format(dateTime, "dd MMM yyyy")}</div>;
};

export default memo(FormattedDate);
