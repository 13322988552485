import React from "react";
import { Radio } from "@edgetier/components";

import SelectOptions from "../select-options";

import { IProps } from "./radios-form-field.types";

/**
 * Radio component for dynamic forms. The options are provided by the backend. Each option has a translation to support
 * multiple languages.
 * @param props.autoFocus Automatically focus on the field when it renders.
 * @param formField       Radio form field.
 * @param languageId      User's language.
 * @returns               Radio buttons.
 */
const Radios = ({ autoFocus, formField, languageId, translation }: IProps) => (
    <div
        aria-label={translation.formFieldTranslation}
        aria-required={formField.required}
        className="dynamic-form__radios"
        role="radiogroup"
    >
        <SelectOptions languageId={languageId} selectOptions={formField.configuration.selectOptions}>
            {({ index, label, selectOptionId }) => (
                <Radio<number, number[]>
                    autoFocus={autoFocus && index === 0}
                    key={selectOptionId}
                    label={label}
                    name={formField.fieldName}
                    option={selectOptionId}
                    transformValue={(option) => [option]}
                />
            )}
        </SelectOptions>
    </div>
);

export default Radios;
