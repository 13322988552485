import React from "react";

import { IProps } from "./none.types";
import "./none.scss";

/**
 * Show a "none" value. This is used in lots of components e.g. to show what languages a user has etc. It applies some
 * minor styling.
 * @returns Span with optional text.
 */
const None = ({ children = "None" }: IProps) => <span className="none">{children}</span>;

export default None;
