import React from "react";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Checked radio button icon.
 * @returns Icon.
 */
const CheckedIcon = () => (
    <FontAwesomeIcon aria-label="radio-checked-icon" fixedWidth={true} icon={faDotCircle} size="lg" />
);

export default CheckedIcon;
