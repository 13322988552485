import React, { FunctionComponent } from "react";
import ReactToggle from "react-toggle";
import { animated, config, useTransition } from "react-spring";

import ToggleDisabled from "./toggle-disabled";
import ToggleEnabled from "./toggle-enabled";

import { IProps } from "./toggle.types";
import "./toggle.scss";

/**
 * Display a toggle control optionally with labels to indicate the current state.
 * Controlled component
 * @param props.checked        State value.
 * @param props.disabled       Should the toggle be disabled
 * @param props.disabledLabel  Label to display when the toggle is disabled.
 * @param props.enabledLabel   Label to display when the toggle is enabled.
 * @param props.onChange       Handle changes to the toggle control.
 * @param props.showStateLabel Whether the state label should be displayed or not.
 * @param props.showIcons      Whether to show icons or not.
 * @param props.showBothLabels If true, show both the enabled and disabled label at the same time.
 * @returns                    Toggle control with a label.
 */
export const Toggle: FunctionComponent<IProps> = ({
    checked,
    disabled,
    label,
    disabledLabel = "Disabled",
    enabledLabel = "Enabled",
    onChange,
    showStateLabel = false,
    showIcons = false,
    showBothLabels = true,
}) => {
    // Animate the enabled/disabled label transition.
    const labelTransitions = useTransition(checked, {
        config: config.stiff,
        initial: null,
        from: { opacity: 0, transform: "translate3d(100%, 0, 0)" },
        enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
        leave: { opacity: 0, transform: "translate3d(100%, 0, 0)" },
    });

    return (
        <label className="toggle">
            {showStateLabel && !showBothLabels && (
                <div className="toggle__state">
                    {labelTransitions((style, item) =>
                        item ? (
                            <animated.div style={style}>
                                <ToggleEnabled>{enabledLabel}</ToggleEnabled>
                            </animated.div>
                        ) : (
                            <animated.div style={style}>
                                <ToggleDisabled>{disabledLabel}</ToggleDisabled>
                            </animated.div>
                        )
                    )}
                </div>
            )}
            {showStateLabel && showBothLabels && <div className="toggle__state--disabled">{disabledLabel}</div>}
            <ReactToggle
                checked={checked}
                onChange={(changeEvent) => onChange(changeEvent.target.checked)}
                icons={showIcons}
                disabled={disabled}
            />
            {label && !showStateLabel && <span className="toggle__label">{label}</span>}
            {showStateLabel && showBothLabels && <div className="toggle__state">{enabledLabel}</div>}
        </label>
    );
};

export default Toggle;
