import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalContext from "../context";

/**
 * Standard button that will appear in lots of modal to just accept a message and close it when clicked.
 * @returns Button.
 */
const OkButton = () => (
    <ModalContext.Consumer>
        {({ hideModal }) => (
            <div className="button" onClick={hideModal}>
                <FontAwesomeIcon icon={faCheck} /> OK
            </div>
        )}
    </ModalContext.Consumer>
);

export default OkButton;
