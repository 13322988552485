import thunk, { ThunkDispatch } from "redux-thunk";
import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { loadingBarMiddleware, loadingBarReducer } from "react-redux-loading-bar";
import { reducer as toastr } from "react-redux-toastr";

import { IApplicationState } from "redux/types";

import authentication from "./modules/authentication/authentication-reducer";
import chat from "./modules/chat";
import email from "./modules/email";
import proactiveChat from "./modules/proactive-chat";
import loadingBlocker from "./modules/loading-blocker";
import modal from "./modules/modal";
import pause from "./modules/pause/pause-reducer";
import reporting from "./modules/reporting";
import setup from "./modules/setup";

const reducers = combineReducers({
    authentication,
    chat,
    email,
    loadingBlocker,
    modal,
    loadingBar: loadingBarReducer,
    pause,
    proactiveChat,
    reporting,
    setup,
    toastr,
});

const middlewares = compose(composeWithDevTools(applyMiddleware(thunk, loadingBarMiddleware())));

type StoreType = Store<IApplicationState, AnyAction> & {
    dispatch: ThunkDispatch<IApplicationState, undefined, AnyAction>;
};

export default createStore(reducers, middlewares) as StoreType;
