import React, { FunctionComponent, memo, useContext } from "react";
import Modal, { ModalContent, ModalHeader, useModal } from "@edgetier/modal";
import { EllipsisMenuContext, EllipsisMenuItem } from "@edgetier/client-components";
import { Button } from "@edgetier/components";
import { IProps } from "./role-action-item.types";
import "./role-action-item.scss";

/**
 * Display a button or a menu action, that opens a modal window containing a form to create or update a role;
 * @param props.heading         Label showed in the header or button for an action
 * @param props.children        Child components
 * @param props.isButton        Show a button if true or a menu action otherwise
 * @param props.menuItemLabel   Text label displayed for ellipsis menu item
 */
const RoleActionItem: FunctionComponent<IProps> = ({ children, heading, isButton, menuItemLabel, icon }) => {
    const { lockOpen, unlock } = useContext(EllipsisMenuContext);
    const { openModal, closeModal, modalProps } = useModal({ onCloseModal: unlock });

    return (
        <>
            <Modal {...modalProps}>
                <ModalHeader>
                    <h2>{heading}</h2>
                </ModalHeader>
                <ModalContent>{typeof children === "function" ? children({ closeModal }) : children}</ModalContent>
            </Modal>

            {typeof isButton === "boolean" && isButton ? (
                <Button icon={icon} type="button" onClick={openModal} styleName="positive">
                    {heading}
                </Button>
            ) : (
                <EllipsisMenuItem
                    closeAfterClick={false}
                    icon={icon}
                    onClick={() => {
                        lockOpen();
                        openModal();
                    }}
                >
                    {menuItemLabel}
                </EllipsisMenuItem>
            )}
        </>
    );
};

export default memo(RoleActionItem);
