import { format } from "date-fns";
import React from "react";
import { DATE_FORMAT, TIME_FORMAT } from "./split-timestamp.constants";
import { IProps } from "./split-timestamp.types";

/**
 * Split a date into date and time so they can be styled separately.
 * @param date A date to format.
 * @returns    Date and time in separate elements.
 */
const SplitTimestamp = ({ date }: IProps) => (
    <span className="split-timestamp">
        <span>{format(date, DATE_FORMAT)}</span>
        <span className="split-timestamp__time">{format(date, TIME_FORMAT)}</span>
    </span>
);

export default SplitTimestamp;
