import React, { FunctionComponent, memo } from "react";
import { Tooltip } from "@edgetier/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import { IProps } from "./field-label-tooltip.types";
import "./field-label-tooltip.scss";

/**
 * A tooltip attached to a field's label to explain what the field does.
 * @param props.label          The label text.
 * @param props.name           The name of the field which the label is for.
 * @param props.tooltipContent The content of the tooltip.
 */
const FieldLabelTooltip: FunctionComponent<IProps> = ({ label, name, tooltipContent, placement = "bottom-center" }) => (
    <label className="field-label-tooltip" htmlFor={name}>
        <span className="field-label-tooltip__label-text">{label}</span>
        <Tooltip content={<div className="field-label-tooltip__content">{tooltipContent}</div>} placement={placement}>
            <div className="field-label-tooltip__container">
                <FontAwesomeIcon icon={faQuestionCircle} />
            </div>
        </Tooltip>
    </label>
);

export default memo(FieldLabelTooltip);
