import React from "react";
import { FunctionComponent, memo } from "react";
import Page from "../page";
import PageHeader from "../page-header";
import { IProps } from "./no-match.types";
import "./no-match.scss";

/**
 * Page rendered when any route is not matched
 */
const NoMatch: FunctionComponent<IProps> = () => {
    return (
        <Page className="not-found">
            <PageHeader title="Page not found" />
        </Page>
    );
};

export default memo(NoMatch);
