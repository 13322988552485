import React from "react";
import { Button } from "@edgetier/components";
import { faChevronDoubleLeft, faChevronDoubleRight } from "@fortawesome/pro-solid-svg-icons";
import { IProps } from "./select-bulk-buttons.types";
import "./select-bulk-buttons.scss";

/**
 * Buttons to select all or no items.
 * @param props.notSelectedItemsCount Number of items not yet selected.
 * @param props.onSelectAll           Function to call when the "select all" button is pressed.
 * @param props.onSelectNone          Function to call when the "select none" button is pressed.
 * @param props.selectedItemsCount    Number of items already selected.
 * @returns                           Component with two buttons.
 */
const SelectBulkButtons = ({
    notSelectedItemsCount,
    isDisabled = false,
    onSelectAll,
    onSelectNone,
    selectedItemsCount,
}: IProps) => {
    return (
        <div className="select-bulk-buttons">
            <Button
                aria-label="Select all"
                disabled={notSelectedItemsCount === 0 || isDisabled}
                icon={faChevronDoubleRight}
                isIconOnly={true}
                onClick={onSelectAll}
                outline={true}
                title="Select all"
            />

            <Button
                aria-label="Select none"
                disabled={selectedItemsCount === 0 || isDisabled}
                icon={faChevronDoubleLeft}
                isIconOnly={true}
                onClick={onSelectNone}
                outline={true}
                styleName="negative"
                title="Select none"
            />
        </div>
    );
};

export default SelectBulkButtons;
