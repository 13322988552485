import React from "react";

import RatingFormField from "../rating-form-field";

import EmptyStar from "./empty-star";
import FilledStar from "./filled-star";
import { IProps } from "./stars-form-field.types";

/**
 * Show a number of stars to allow customers to rate their chat experience.
 * @param props Props to pass to the rating component.
 * @returns     Several stars that the user can use to rate something.
 */
const StarsFormField = (props: IProps) => (
    <div className="dynamic-form__stars">
        <RatingFormField {...props}>
            {({ isSelected }) => (isSelected ? <FilledStar /> : <EmptyStar />)}
        </RatingFormField>
    </div>
);

export default StarsFormField;
