import QueryLinkIndicators from "./query-link-indicators";
import { NavigationLink } from "@edgetier/client-components";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { AbsolutePaths } from "constants/routes";
import { PageNames } from "types-for/routes";

/**
 * Link to the query screen.
 * @returns A link to the page.
 */
const QueryLink = () => (
    <NavigationLink label="Email" icon={faEnvelope} page={AbsolutePaths[PageNames.Email]()}>
        <QueryLinkIndicators />
    </NavigationLink>
);

export default QueryLink;
