import React from "react";

import Spinner from "../spinner/spinner";

import { IProps } from "./spinner-until.types";

/**
 * Show a spinner until some content is ready.
 * @param props.children Children to display when ready.
 * @param props.isReady  True when the content is loaded.
 * @returns              A spinner while something is loading or the content when it's available.
 */
function SpinnerUntil<T>({ children, data, isReady, spinner }: IProps<T>) {
    const SpinnerComponent = spinner || Spinner;
    return isReady ? <>{typeof children === "function" ? children(data) : children}</> : <SpinnerComponent />;
}

export default SpinnerUntil;
