import React from "react";
import { FunctionComponent } from "react";
import { IProps } from "./table-menu-section.types";
import "./table-menu-section.scss";

/**
 * A section in a table's menu (only really used to apply styling).
 * @param props.children Section content.
 * @returns              Wrapped content.
 */
const TableMenuSection: FunctionComponent<IProps> = ({ children }) => {
    return <div className="grid__menu-section">{children}</div>;
};

export default TableMenuSection;
