import React from "react";

import Modal from "../modal";
import ModalContext from "../context";

import { IProps } from "./wrapper.types";

/**
 * Context wrapper around modal component.
 */
const ModalWrapper = ({ children, ...otherProps }: IProps) => (
    <ModalContext.Consumer>
        {(context) => (context.isOpen ? <Modal {...{ ...context, ...otherProps }}>{children}</Modal> : null)}
    </ModalContext.Consumer>
);

export default ModalWrapper;
