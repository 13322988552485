import { Box, BoxContent, LoadingSkeleton, None, NumericValue } from "~/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FunctionComponent, memo } from "react";
import { IProps } from "./metric-box.types";
import MetricBoxActionsMenu from "./metric-box-actions-menu";
import { Tooltip } from "@edgetier/components";
import "./metric-box.scss";

/**
 * Display a metric value optionally versus another overall value.
 * @param props.value          Value for the metric
 * @param props.icon           Icon for the metric.
 * @param props.isLoading      If the data is loading.
 * @param props.menuOptions    Menu actions for metrics
 * @param props.title          Label/description for the metric.
 * @param props.tooltipContent Explanation of the metric.
 * @param props.overallValue   Same metric value for all other agents.
 * @returns                    A metric icon, label, and values.
 */
const MetricBox: FunctionComponent<IProps> = ({
    comparisonValue,
    icon,
    isLoading,
    menuOptions,
    renderValue = (value, otherProps) => <NumericValue value={value} {...otherProps} />,
    title,
    tooltipContent,
    value,
    ...numericValueProps
}) => {
    return (
        <Box className="metric-box">
            <Tooltip content={<div className="metric-box__tooltip">{tooltipContent}</div>} useArrow>
                <BoxContent>
                    <div className="metric-box__header">
                        <FontAwesomeIcon fixedWidth={true} icon={icon} />
                        <div className="metric-box__title">{title}</div>
                        {typeof menuOptions !== "undefined" && (
                            <MetricBoxActionsMenu>{menuOptions}</MetricBoxActionsMenu>
                        )}
                    </div>
                    <div className="metric-box__values">
                        <div className="metric-box__agent-value">
                            {isLoading ? (
                                <LoadingSkeleton width={50} containerTestId="metric-box__agent-loading" />
                            ) : typeof value === "number" ? (
                                renderValue(value, numericValueProps)
                            ) : (
                                <None>N/A</None>
                            )}
                        </div>

                        {typeof comparisonValue !== "undefined" && (
                            <div className="metric-box__overall-value">
                                {isLoading ? (
                                    <LoadingSkeleton containerTestId="metric-box__overall-loading" />
                                ) : (
                                    <>
                                        vs{" "}
                                        <span>
                                            {typeof comparisonValue === "number" ? (
                                                renderValue(comparisonValue, numericValueProps)
                                            ) : (
                                                <None>N/A</None>
                                            )}
                                        </span>{" "}
                                        overall
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </BoxContent>
            </Tooltip>
        </Box>
    );
};

export default memo(MetricBox);
