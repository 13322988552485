import React from "react";

import { IProps } from "./details-section.types";
import "./details-section.scss";

/**
 * Section of miscellaneous details provided by the backend.
 * @param props.children Contents of the section.
 * @returns              Section component.
 */
const DetailsSection = ({ children }: IProps) => <section className="details-section">{children}</section>;

export default DetailsSection;
