import React, { FunctionComponent, memo } from "react";

import { IProps } from "./box-header.types";
import "./box-header.scss";

/**
 * Box header.
 * @param props.children Header content.
 * @returns              Header for a box.
 */
const BoxHeader: FunctionComponent<IProps> = ({ children }) => {
    return <div className="box-header">{children}</div>;
};

export default memo(BoxHeader);
