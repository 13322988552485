enum EmailType {
    ClearTimers = "email/CLEAR_ALL_TIMERS",
    Remove = "email/REMOVE_EMAIL",
    Store = "email/STORE_EMAIL",
    StoreWrapUpDraft = "email/STORE_WRAP_UP_DRAFT",
    Toggle = "email/TOGGLE_EMAIL",
    ToggleQueueEmpty = "email/TOGGLE_QUEUE_EMPTY",
    Update = "email/UPDATE_EMAIL",
}

export default EmailType;
