import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

import { IProps } from "./conditional-icon.types";

/**
 * Show one of two icons depending on a condition.
 * @param props.condition Condition to evaluate.
 * @param props.iconFalse Icon to display if the condition fails.
 * @param props.iconTrue  Icon to display if the condition passes.
 * @returns               An icon.
 */
const ConditionalIcon = ({ classNameFalse, classNameTrue, condition, iconFalse, iconTrue, ...iconProps }: IProps) => (
    <FontAwesomeIcon
        className={
            typeof classNameFalse !== "string" || typeof classNameTrue !== "string"
                ? undefined
                : classNames({
                      [classNameFalse]: condition === false,
                      [classNameTrue]: condition === true,
                  })
        }
        icon={condition ? iconTrue : iconFalse}
        fixedWidth={true}
        {...iconProps}
    />
);

export default ConditionalIcon;
