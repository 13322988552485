import classNames from "classnames";
import React, { FunctionComponent, memo } from "react";

import { IProps } from "./box-content.types";
import "./box-content.scss";
/**
 * Content for a box component. Mostly to add padding.
 * @param props.children          Box content.
 * @param props.className         Optional class name.
 * @param props.horizontalPadding Whether to apply horizontal padding or not.
 * @param props.verticalPadding   Whether to apply vertical padding or not.
 * @returns                       Box content container.
 */
const BoxContent: FunctionComponent<IProps> = ({
    children,
    className,
    horizontalPadding = true,
    verticalPadding = true,
}) => {
    return (
        <div
            className={classNames("box-content", className, {
                "box-content--horizontal-padding": horizontalPadding === true,
                "box-content--vertical-padding": verticalPadding === true,
            })}
        >
            {children}
        </div>
    );
};

export default memo(BoxContent);
