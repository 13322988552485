import { FunctionComponent, memo } from "react";

import InteractionType from "constants/interaction-type";
import { ampersandList } from "@edgetier/client-components";

import { IProps } from "./query-history-title-description.types";
import { useSelector } from "react-redux";
import { IApplicationState } from "redux/types";
import { getFeatureToggles } from "redux/modules/setup/setup-selectors";
import { PROACTIVE_CHAT_ENABLED } from "constants/feature-flags";
import "./query-history-title-description.scss";

/**
 * Partial title for the interaction search thread modal.
 * @param props.interactionTypeIds All the interaction types in the thread.
 * @returns                        Title element.
 */
const QueryHistoryTitleDescription: FunctionComponent<IProps> = ({ interactionTypeIds, isProactiveChat }) => {
    const { proactiveChatEnabled } = useSelector(({ setup }: IApplicationState) => getFeatureToggles(setup));

    return (
        <>
            {ampersandList(
                interactionTypeIds
                    .filter((item, index, items) => items.indexOf(item) === index)
                    .map((interactionTypeId) => {
                        if (
                            interactionTypeId === InteractionType.Chat &&
                            PROACTIVE_CHAT_ENABLED &&
                            proactiveChatEnabled
                        ) {
                            return isProactiveChat ? "Proactive Chat" : "Reactive Chat";
                        }

                        return InteractionType[interactionTypeId];
                    })
            )}
        </>
    );
};

export default memo(QueryHistoryTitleDescription);
