import { UserEvent } from "@testing-library/user-event/dist/types/setup/setup";
import clickMenuButton from "./get-menu-button";
import openSelectMenu from "./open-select-menu";
import { act, Matcher, screen } from "@testing-library/react";

/**
 * Get the text of the labels in the menu.
 * @param options Optional configuration options.
 * @returns           Array of label text.
 */
const getSelectLabelsText = async (options?: { menuMatcher?: Matcher; userEventInstance: UserEvent }) => {
    let labels: (string | undefined)[] = [];
    await act(async () => {
        await openSelectMenu({ menuMatcher: options?.menuMatcher, userEventInstance: options?.userEventInstance });
        labels = screen.getAllByRole("option").map(({ textContent }) => textContent?.trim());
        clickMenuButton({ menuMatcher: options?.menuMatcher, userEventInstance: options?.userEventInstance });
    });
    return labels;
};

export default getSelectLabelsText;
