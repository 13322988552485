import { IFormValues } from "@edgetier/dynamic-form";
import EmailType from "./email-type";
import { IEmail, IEmailState } from "./email.types";

const createAction = <T extends { type: EmailType }>(action: T) => action;

/**
 * Log clear timers action, this shouldn't affect state but is dispatched via Redux action
 * @return  ClearAllTimers action
 */
const clearTimers = () => createAction({ type: EmailType.ClearTimers });

/**
 * Remove an email.
 * @param type Email to remove.
 * @returns    Remove email action.
 */
const removeEmail = (type: keyof IEmailState["emails"]) =>
    createAction({
        type: EmailType.Remove,
        payload: { type },
    });

/**
 * Store an email.
 * @param type  Which email to store.
 * @param email Email details.
 * @returns     Store email action.
 */
const storeEmail = (type: keyof IEmailState["emails"], email: IEmail) =>
    createAction({
        type: EmailType.Store,
        payload: { email, type },
    });

/**
 * Store a draft of the wrap form for an email.
 * @param type       Which email to update.
 * @param formValues The form values of the wrap up form being saved.
 * @returns          Store the wrap-up form draft.
 */
const storeWrapUpFormDraft = (type: keyof IEmailState["emails"], formValues: IFormValues) =>
    createAction({
        type: EmailType.StoreWrapUpDraft,
        payload: { formValues, type },
    });

/**
 * Enable or disable email.
 * @param payload State to set the email availability.
 * @returns       Toggle email action.
 */
const toggleEmail = (payload: boolean) =>
    createAction({
        type: EmailType.Toggle,
        payload,
    });

/**
 * Track if there are no emails in the overall queue.
 * @param payload State to set the queue empty state.
 * @returns       Toggle queue empty action.
 */
const toggleQueueEmpty = (payload: boolean) =>
    createAction({
        type: EmailType.ToggleQueueEmpty,
        payload,
    });

/**
 * Update some part of an email.
 * @param type  Which email to update.
 * @param email Email details.
 * @returns     Store email action.
 */
const updateEmail = (type: keyof IEmailState["emails"], email: Partial<IEmail>) =>
    createAction({
        type: EmailType.Update,
        payload: { email, type },
    });

export default {
    removeEmail,
    clearTimers,
    storeEmail,
    storeWrapUpFormDraft,
    toggleEmail,
    toggleQueueEmpty,
    updateEmail,
};
