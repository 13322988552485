import format from "date-fns/format";

import { IProps } from "./message-time.types";
import "./message-time.scss";

/**
 * Display the time a message was sent.
 * @param props.timestamp The time of the message.
 * @returns               Formatted time string.
 */
const MessageTime = ({ date }: IProps) => (
    <div className="chat-messages__message__message-time">{format(date, "dd/MM/yyyy HH:mm")}</div>
);

export default MessageTime;
