import React from "react";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons/faTimesCircle";

import { IProps } from "./boolean-icon.types";
import "./boolean-icon.scss";

import ConditionalIcon from "~/conditional-icon";
import None from "~/none";

/**
 * Show an icon to represent a true or false value.
 * @param props Boolean value.
 * @returns     Icons representing true or false.
 */
const BooleanIcon = ({ value }: IProps) => {
    if (typeof value !== "boolean") {
        return <None>N/A</None>;
    }

    return (
        <div className="boolean-icon">
            <ConditionalIcon
                className={value ? "boolean-icon--true" : "boolean-icon--false"}
                condition={value}
                iconFalse={faTimesCircle}
                iconTrue={faCheckCircle}
            />
        </div>
    );
};

export default BooleanIcon;
