import React from "react";
import { Collapse, UnmountClosed } from "react-collapse";

import { IProps } from "./accordion-item-content.types";
import "./accordion-item-content.scss";

import useAccordionItemContext from "~/accordion/use-accordion-item-context";

/**
 * Aniamted expandable content inside an accordion item.
 * @param props.children Accordion content.
 * @returns              Accordion content.
 */
const AccordionItemContent = ({ children, keepMounted, onToggle }: IProps) => {
    const { isOpen } = useAccordionItemContext();
    const CollapseComponent = keepMounted ? Collapse : UnmountClosed;
    return (
        <div className="accordion__accordion-item-content">
            <CollapseComponent isOpened={isOpen} onWork={() => onToggle && onToggle(isOpen)}>
                {children}
            </CollapseComponent>
        </div>
    );
};

export default AccordionItemContent;
