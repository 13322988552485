import React, { Fragment } from "react";

import None from "../none";

import { IProps } from "./numeric-value.types";
import { NEGATIVE_ZERO_PATTERN } from "./numeric-value.constants";
import "./numeric-value.scss";

/**
 * Display a numeric value with optional rounding.
 * @param props.value The value to display.
 * @returns           Value.
 */
const NumericValue = ({ options, unit, value }: IProps) => {
    if (typeof value !== "number") {
        return <None>N/A</None>;
    }

    // Perform any necessary rounding but don't allow negative zero values like "-0".
    const formatted = options ? value.toLocaleString(undefined, options) : value.toLocaleString();
    const finalNumber = NEGATIVE_ZERO_PATTERN.test(formatted) ? formatted.replace(/^-/, "") : formatted;

    return (
        <Fragment>
            {finalNumber}
            {typeof unit !== "undefined" && <span className="numeric-value__unit">{unit}</span>}
        </Fragment>
    );
};

export default NumericValue;
