import { PROACTIVE_CHAT_ENABLED } from "constants/feature-flags";
import { createSelector } from "reselect";

import { ISetupState, ISetupData } from "./setup.types";

// Defaults when the setup data is unavailable.
export const DEFAULT_BOOKING_DESCRIPTION = "Booking";
export const DEFAULT_BOOKING_ID_NAME = "Booking ID";
export const DEFAULT_BOOKING_ID_PATTERN = /.+/;
export const DEFAULT_TRANSLATIONS_ENABLED = false;

/**
 * Get setup data from the setup state.
 * @param state Setup state.
 * @returns     Setup data property.
 */
const selectData = ({ data }: ISetupState) => data;

/**
 * Select the booking description. This is used to label what each client calls their own customer's details. E.g. it
 * could be "Booking", "Membership Details" etc.
 * @returns Booking description selector.
 */
export const getBookingDescription = createSelector(selectData, (data) =>
    typeof data === "undefined" ? DEFAULT_BOOKING_DESCRIPTION : data.bookingDescription
);

/**
 * Get the label for the booking ID field.
 * @returns Booking ID name selector.
 */
export const getBookingIdName = createSelector(selectData, (data) =>
    typeof data === "undefined" ? DEFAULT_BOOKING_ID_NAME : data.bookingIdName
);

/**
 * Get the validation RegEx for the booking ID field.
 * @param state Setup state.
 * @returns     Booking ID pattern.
 */
export const getBookingIdPattern = createSelector(selectData, (data) => {
    try {
        return typeof data === "undefined" ? DEFAULT_BOOKING_ID_PATTERN : new RegExp(data.bookingIdPattern);
    } catch {
        return DEFAULT_BOOKING_ID_PATTERN;
    }
});

/**
 * Get the languages from the setup data.
 * @returns Array of languages selector.
 */
export const getLanguages = createSelector(selectData, (data) => (typeof data === "undefined" ? [] : data.languages));

/**
 * How many minutes agents have to handle an email once it has been routed to them"
 * @param state SetupState
 * @returns     Minutes allowed for handling an email
 */
export const getEmailAllowedTimeMinutes = createSelector(selectData, (data) =>
    typeof data === "undefined" ? null : data.emailAllowedTimeMinutes
);

/**
 * Get the translations enabled state. Not all clients will have this feature turned on.
 * @returns Translation enabled state selector.
 */
export const getTranslationsEnabled = createSelector(selectData, (data) =>
    typeof data === "undefined" ? DEFAULT_TRANSLATIONS_ENABLED : data.translationsEnabled
);

/**
 * Get only the feature toggles from setup
 */
export const getFeatureToggles = createSelector(selectData, (data) => {
    const setup = typeof data === "undefined" ? ({} as ISetupData) : data;

    return {
        blockedQueryStateEnabled: Boolean(setup.blockedQueryStateEnabled),
        callFunctionalityEnabled: Boolean(setup.callFunctionalityEnabled),
        chatFunctionalityEnabled: Boolean(setup.chatFunctionalityEnabled),
        chatSkillsEnabled: Boolean(setup.chatSkillsEnabled),
        departmentRestrictedBrandsEnabled: Boolean(setup.departmentRestrictedBrandsEnabled),
        departmentRestrictedSetupsEnabled: Boolean(setup.departmentRestrictedSetupsEnabled),
        departmentRestrictedTransfersEnabled: Boolean(setup.departmentRestrictedTransfersEnabled),
        emailAllowedTimeMinutes: Number(setup.emailAllowedTimeMinutes),
        emailFunctionalityEnabled: Boolean(setup.emailFunctionalityEnabled),
        emailSkillsEnabled: Boolean(setup.emailSkillsEnabled),
        proactiveChatEnabled: Boolean(setup.proactiveChatEnabled) && PROACTIVE_CHAT_ENABLED,
    };
});
