import React, { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./field-inline.types";
import classNames from "classnames";
import "./field-inline.scss";

/**
 * Form field inline with icon
 * @param props.icon        Optional icon displayed
 * @param props.children    Children component
 */
const FieldInline: FunctionComponent<IProps> = ({ icon, children }) => {
    const hasIcon = typeof icon !== "undefined";

    return (
        <div className={classNames("field", "field-inline", { "field-inline--no-icon": !hasIcon })}>
            {hasIcon && (
                <div className="field-inline__icon">
                    <FontAwesomeIcon icon={icon} />
                </div>
            )}
            {children}
        </div>
    );
};

export default memo(FieldInline);
