import React from "react";

import ArrayDetailsSection from "./array-details-section";
import { IProps } from "./array-details-field.types";
import "./array-details-field.scss";

import { DetailsFieldTitle } from "~/details";
import { Accordion } from "~/accordion";

/**
 * Display a details row containing a collapsible field.
 * @param props.field Array field.
 * @returns           Table row.
 */
const ArrayDetailsField = ({ field }: IProps) => (
    <tr className="array-details-field">
        <td colSpan={2}>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
            <Accordion>
                {Array.isArray(field.properties.sections) &&
                    field.properties.sections.map((section) => (
                        <ArrayDetailsSection section={section} key={section.title} />
                    ))}
            </Accordion>
        </td>
    </tr>
);

export default ArrayDetailsField;
