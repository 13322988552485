import React, { FunctionComponent, memo, useRef } from "react";
import classNames from "classnames";
import { useIsOverflowed } from "@edgetier/utilities";
import { IProps } from "./labeled-field.types";
import { Tooltip } from "@edgetier/components";
import "./labeled-field.scss";

/**
 * Wrap any field and display a label
 * @param props.label       Label displayed
 * @param props.children    Field component(s)
 */
const LabeledField: FunctionComponent<IProps> = ({ label, children, name }) => {
    const labelRef = useRef(null);
    const isOverflowed = useIsOverflowed(labelRef, "width");

    return (
        <div className={classNames("field", "labeled-field")}>
            <Tooltip useArrow content={label} disableTooltip={!isOverflowed} placement="top-center">
                <label ref={labelRef} htmlFor={name} className="labeled-field__label" id={`${name}-label`}>
                    {label}
                </label>
            </Tooltip>
            {children}
        </div>
    );
};

export default memo(LabeledField);
