import io from "socket.io-client";
import legacyIo from "legacy-socket.io-client";
import { getSocketUrl } from "@edgetier/utilities";
import { retrieveAuthenticationDetails } from "utilities/local-storage";
import axios from "utilities/axios";
import { Url } from "@edgetier/types";
/* eslint-disable-next-line import/no-webpack-loader-syntax */
import IntervalWorker from "worker-loader!./interval-worker";

const CONNECT_EVENT = "connect";
const DISCONNECT_EVENT = "disconnect";
const PING_EVENT = "ping";

// A web worker that manages the ping interval. The interval in the worker will not be throttled when the tab is
// inactive. Ordinarily, the interval would be throttled when the tab is inactive for 5 minutes.
const intervalWorker = new IntervalWorker();

// Initialise Socket IO connection but don't connect yet (waiting until the agent signs in).
const maybeSocket = (async () => {
    const socketConfiguration = { autoConnect: false, reconnectionAttempts: 10 };
    const socketUrl = getSocketUrl();
    try {
        const { data } = await axios.get(Url.SocketDetails);
        const version = Math.max(data.socketioVersions);

        if (version >= 5) {
            return io(socketUrl, socketConfiguration);
        }
        return legacyIo(socketUrl, socketConfiguration);
    } catch (e) {
        return legacyIo(socketUrl, socketConfiguration);
    }
})();

/**
 * Return the socket instance.
 * @returns The socket instance.
 */
export async function getSocket() {
    return await maybeSocket;
}

/**
 * Connect to the socket. This only happens once a user is logged in.
 * @param roleId The user's role.
 */
export async function connectSocket() {
    const { token, userId } = retrieveAuthenticationDetails();
    if (token === null || userId === null) {
        return;
    }

    const socket = await maybeSocket;
    // Connect with the agent's connection details.
    socket.io.opts.query = { token, user_id: userId.toString() };
    socket.open();

    // Set up an interval so the frontend continually lets the backend know its active.
    socket.on(CONNECT_EVENT, () => {
        socket.emit(PING_EVENT);
        intervalWorker.postMessage("start");

        intervalWorker.onmessage = (event) => {
            // Only emit the ping event if the socket is connected. Otherwise, the events are buffered and a huge number
            // of events are emitted when the socket reconnects and this can cause issues on the backend.
            if (event.data === "ping" && socket.connected) {
                socket.emit(PING_EVENT);
            }
        };
    });

    socket.on(DISCONNECT_EVENT, () => {
        intervalWorker.postMessage("stop");
    });
}

/**
 * Disconnect from the socket if it is open. This will happen when agents log out.
 */
export async function disconnectSocket() {
    const socket = await maybeSocket;
    if (typeof socket !== "undefined" && socket.connected) {
        socket.disconnect();
    }
}
