/**
 * Limit an array to include only items of a known enum value. This is stop trying to display things on an unknown type
 * from the backend. For example, when the backend introduces a new form field type, the frontend won't know how to
 * display it until it is updated.
 * @param enumObject The enum containing whatever values are allowed.
 * @param items      Array of items to check.
 * @param getId      Method to retrieve the enum ID for the a given item.
 * @returns          The array of items with any items of an unknown enum value removed.
 */
export function intersectWithEnum<T, Item>(enumObject: T, items: Item[], getId: (item: Item) => number) {
    const enumIds = Object.values(enumObject).filter((value) => typeof value === "number");
    return items.filter((item) => enumIds.includes(getId(item)));
}
