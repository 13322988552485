import { format } from "date-fns";

/**
 * Format the date in an appropriate way for the field.
 * @param date The date to format.
 * @returns If the date is valid, the formatted date, otherwise, undefined.
 */
export const formatDate = (date: string | null | undefined) => {
    return typeof date === "string" ? format(new Date(date), "yyyy-MM-dd") : undefined;
};
