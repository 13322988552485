import React from "react";

import GridContext from "~/grid/grid-context";

/**
 * Show something in the table if there is an error.
 * @returns Table row.
 */
const Error = () => (
    <GridContext.Consumer>
        {({ columnCount, errorMessage }) => (
            <tr className="grid__error">
                <td colSpan={columnCount}>{errorMessage}</td>
            </tr>
        )}
    </GridContext.Consumer>
);

export default Error;
