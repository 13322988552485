import classNames from "classnames";
import copyToClipboard from "copy-to-clipboard";
import React from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IProps } from "./details-field-value.types";
import "./details-field-value.scss";

import TooltipWarning from "~/tooltip-warning";
import None from "~/none";
import LoadingSkeleton from "~/loading-skeleton";

/**
 * Display a details field's value and maybe a warning if the backend has provided one.
 * @param props.children Field value or any content it wants to display.
 * @param props.copyText Optional text to copy to the clipboard on click.
 * @param props.warning  Optional warning text.
 * @returns              Field value and optional warning.
 */
const DetailsFieldValue = ({ children, copyText, isLoading, isNull, warning, isError }: IProps) => {
    /**
     * Copy text to the clipboard if some text has been provided as a prop.
     */
    const copy = () => {
        if (typeof copyText === "string") {
            copyToClipboard(copyText);
        }
    };

    return (
        <div
            className={classNames("details-field-value", {
                "details-field-value--can-copy": typeof copyText === "string" && !isNull,
            })}
        >
            <div className="details-field-value__copy-icon">
                <FontAwesomeIcon icon={faCopy} />
            </div>
            {isError && <None>N/A</None>}
            {isNull && !isError && <None />}
            {!isNull && !isError && (
                <div
                    className={classNames("details-field-value__value", {
                        "details-field-value__value--loading": isLoading,
                    })}
                    onClick={copy}
                >
                    {isLoading ? <LoadingSkeleton inline /> : children}
                </div>
            )}

            {typeof warning === "string" && <TooltipWarning warning={warning} />}
        </div>
    );
};

export default DetailsFieldValue;
