import classNames from "classnames";
import React, { FunctionComponent } from "react";

import { IProps } from "./box.types";
import "./box.scss";

/**
 * A basic container with some styling.
 * @param props.children  Box content.
 * @param props.className Optional extra class name(s) to add.
 * @returns               Content in a container.
 */
const Box: FunctionComponent<IProps> = ({ children, className }) => {
    return (
        <div className={classNames("box", className)} role="group">
            {children}
        </div>
    );
};

export default Box;
