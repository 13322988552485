import React, { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "@edgetier/components";
import { IProps } from "./permission-summary.types";
import { formatUnderscoreName } from "@edgetier/utilities";
import "./permission-summary.scss";

/**
 * Summary details for permission
 * @param props.permission      Information about permission
 */
const PermissionSummary: FunctionComponent<IProps> = ({ permission, permissionDescription }) => (
    <div className="permission-summary">
        <div className="permission-summary__title">{formatUnderscoreName(permission.permission)}</div>

        {typeof permissionDescription === "string" && permissionDescription !== "" && (
            <Tooltip content={permissionDescription}>
                <div className="permission-summary__description-info">
                    <FontAwesomeIcon icon={faInfoCircle} />
                </div>
            </Tooltip>
        )}
    </div>
);

export default memo(PermissionSummary);
