import React from "react";
import { Markdown } from "@edgetier/components";

import { IProps } from "./message-text.types";

/**
 * Show the content of a chat message. All URLs are detected and turned into clickable links. Depending on whether this
 * is an agent or customer, the links will open in a new tab (agents) or the parent window (customers).
 *
 * @param props.addAccessibilityRole Whether to add a accesibility alert or not. This will announce the message to
 *                                   screen readers. It's only needed for received messages.
 * @param props.text                 Message content.
 * @param props.linkTarget           Where to open links (could be the parent or new tab).
 * @returns                          Message text.
 */
const MessageText = ({ addAccessibilityRole, linkTarget, translation }: IProps) => (
    <div tabIndex={0} role={addAccessibilityRole ? "alert" : undefined}>
        {typeof translation === "string" ? <Markdown linkTarget={linkTarget} markdownString={translation} /> : null}
    </div>
);

export default MessageText;
