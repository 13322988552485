import React, { FunctionComponent, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProps } from "./interaction-icon.types";
import { getInteractionIcon } from "./interaction-icon.utilities";
import "./interaction-icon.scss";

/**
 * Display consistent interaction type icons for all applications
 * @param props.interactionIconType     Interaction type
 * @param props.iconProps               Font awesome icon props
 */
const InteractionIcon: FunctionComponent<IProps> = ({ interactionIconType, ...iconProps }) => (
    <div className={`interaction-icon interaction-icon__${interactionIconType}`}>
        <FontAwesomeIcon icon={getInteractionIcon(interactionIconType)} fixedWidth {...iconProps} />
    </div>
);

export default memo(InteractionIcon);
