import React from "react";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Icon for system-generated chat messages. This is a separate component for testing purposes.
 * @returns Icon.
 */
const AutomatedIcon = () => <FontAwesomeIcon icon={faBolt} />;

export default AutomatedIcon;
