import { AnyAction } from "redux";
import { hideLoading, showLoading } from "react-redux-loading-bar";
import { ThunkAction } from "redux-thunk";
import { Url } from "@edgetier/types";
import Axios from "axios";

import axios from "utilities/axios";
import dispatchServerError from "utilities/dispatch-server-error";
import { IApplicationState } from "redux/types";

import actions from "./reporting-actions";

type IReportingThunk = ThunkAction<any, IApplicationState, void, AnyAction>;

/**
 * Download historic reporting data.
 * @returns Thunk action.
 */
export function requestReportingHistoric(startDate: string, endDate: string): IReportingThunk {
    return async (dispatch) => {
        try {
            dispatch(showLoading());
            const response = await axios.post(Url.ReportingHistoric, { startDate, endDate });
            dispatch(actions.storeHistoric(response.data));
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                dispatchServerError(dispatch, "Failed to get reporting data.")(serverError);
            }
        } finally {
            dispatch(hideLoading());
        }
    };
}

export default { ...actions, requestReportingHistoric };
