import React from "react";
import { FunctionComponent, memo } from "react";
import { Button } from "@edgetier/components";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";
import useRemoveRole from "~/hooks/use-remove-role";

import { IProps } from "./remove-role.types";
import "./remove-role.scss";
import { formatUnderscoreName } from "@edgetier/utilities";

/**
 * Remove a role. Opens a modal window as a confirmation message
 * @param props.actionLabel     Label used in modal header and button
 * @param props.routeUrl        API roles route
 * @param props.axiosInstance
 * @param props.role            Role intended to be removed
 */
const RemoveRoleAction: FunctionComponent<IProps> = ({ routeUrl, axiosInstance, role, toggleAction }) => {
    const { mutate: removeRoleMutate, isLoading } = useRemoveRole(routeUrl, axiosInstance, role, {
        onSettled: toggleAction,
    });

    return (
        <>
            <div className="remove-role">
                <p>Are you sure you want remove the role &ldquo;{formatUnderscoreName(role.role)}&rdquo;?</p>
            </div>
            <div className="remove-role__button">
                <Button
                    icon={faTrash}
                    styleName="negative"
                    onClick={() => removeRoleMutate(role)}
                    isLoading={isLoading}
                >
                    Delete role
                </Button>
            </div>
        </>
    );
};

export default memo(RemoveRoleAction);
