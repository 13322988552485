import classNames from "classnames";
import GridProvider from "../grid-provider";
import Header from "../header";
import React from "react";
import TableBody from "./table-body";
import TableFooter from "../table-footer";
import TableHeading from "./table-heading";
import { Children, isValidElement, ReactElement } from "react";
import { IProps } from "./table.types";
import "./table.scss";

/**
 * Main content of the table.
 * @param props.children        Elements displayed
 * @param props.className       Class name for table element
 * @param props.headerIsSticky
 * @param props.hasHorizontalScroll Table can be scrolled horizontally
 * @param props.isInBox
 * @returns Table heading, body, and maybe pagination.
 */
export const Table = <T extends {}, F extends {} = {}>({
    children,
    className,
    headerIsSticky = false,
    isInBox = false,
    hasHorizontalScroll = false,
    expandedColumnCount = 20,
    ...otherProps
}: IProps<T, F>) => {
    const contents = Children.toArray(children).filter((child): child is ReactElement => isValidElement(child));

    return (
        <GridProvider
            // The column count can be calculated by counting the number of Column components there are.
            columnCount={expandedColumnCount}
            // The table re-renders whenever the rows change or starts/stops loading. The easiest way to detect new content
            // is to concatenate all the row keys together. Unless the table is loading, and then a fixed string is the key.
            key={
                otherProps.hasError
                    ? "__error__"
                    : !Array.isArray(otherProps.rows)
                    ? "__loading__"
                    : Array.isArray(otherProps.rows) && otherProps.rows.length === 0
                    ? "__empty__"
                    : (otherProps.fixedRows || []).concat(otherProps.rows).map(otherProps.getRowKey).join("___")
            }
            // The page size will be different if there are some fixed rows at the top of the table.
            {...{
                ...otherProps,
                pageSize:
                    typeof otherProps.pageSize === "number" && Array.isArray(otherProps.fixedRows)
                        ? otherProps.pageSize - otherProps.fixedRows.length
                        : otherProps.pageSize,
            }}
        >
            <div
                className={classNames("grid", {
                    "grid--has-buttons": false,
                    "grid--is-in-box": isInBox,
                    "grid--sticky-header": headerIsSticky,
                })}
            >
                {contents.filter((child) => child.type === Header)}

                <table
                    className={classNames(className, {
                        "table--has-horizontal-scroll": hasHorizontalScroll,
                    })}
                >
                    <TableHeading>{children}</TableHeading>
                    <TableBody>{children}</TableBody>
                </table>

                {contents.filter((child) => child.type === TableFooter)}
            </div>
        </GridProvider>
    );
};

export default Table;
