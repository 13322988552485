import React from "react";
import { Field, FieldProps } from "formik";
import { Checkbox } from "@edgetier/components";

import SelectOptions from "../select-options";

import { IProps } from "./checkboxes-form-field.types";

/**
 * Checkboxes component for dynamic forms. The options are provided by the backend. Each option has a translation to
 * support multiple languages.
 * @param props Props containing field details.
 * @returns     Radio buttons.
 */
const CheckboxesFormField = ({ autoFocus, formField, languageId, translation }: IProps) => (
    <fieldset
        aria-label={translation.formFieldTranslation}
        aria-required={formField.required}
        className="dynamic-form__radios"
    >
        <Field name={formField.fieldName}>
            {({ field: { name, value } }: FieldProps) => (
                <SelectOptions languageId={languageId} selectOptions={formField.configuration.selectOptions}>
                    {({ index, label, selectOptionId }) => {
                        const values = value || [];

                        return (
                            <Checkbox<number[]>
                                autoFocus={autoFocus && index === 0}
                                checked={values.includes(selectOptionId)}
                                key={selectOptionId}
                                label={label}
                                name={name}
                                transformValue={(checked) =>
                                    checked
                                        ? values.concat(selectOptionId)
                                        : (values as number[]).filter((optionId) => optionId !== selectOptionId)
                                }
                            />
                        );
                    }}
                </SelectOptions>
            )}
        </Field>
    </fieldset>
);

export default CheckboxesFormField;
