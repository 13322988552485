import React from "react";
import { doNothing } from "@edgetier/utilities";

import { IGridContext } from "./grid-context.types";

export const defaultContext: IGridContext<any, any> = {
    activeRowKey: null,
    clearSort: doNothing,
    collapseRow: doNothing,
    columnCount: 0,
    constantRows: false,
    constantWidth: true,
    emptyMessage: "No results found",
    errorMessage: "An error occurred",
    expandedRowKey: null,
    expandRow: doNothing,
    filters: { search: "" },
    onFiltersChange: doNothing,
    fixedRows: [],
    getRowKey: JSON.stringify,
    getRowLink: undefined,
    goToNextPage: doNothing,
    goToPreviousPage: doNothing,
    hasError: false,
    hasNext: null,
    hasPrevious: null,
    hasSetWidth: false,
    highlightedRowsKeys: undefined,
    isSortedAscending: true,
    onRowClick: undefined,
    pageIndex: 0,
    pageSize: Number.POSITIVE_INFINITY,
    rows: null,
    searchKeys: null,
    setFilters: doNothing,
    setHasSetWidth: doNothing,
    sort: doNothing,
    sortProperty: null,
    sortValues: [],
};

const GridContext = React.createContext<IGridContext<any, any>>(defaultContext);
export default GridContext;
