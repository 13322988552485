import { IndexRouteObject, LazyRouteFunction, NonIndexRouteObject, RouteObject } from "react-router-dom";

export enum PageNames {
    AgentHome = "AgentHome",
    Authenticate = "Authenticate",
    AuthenticatedRedirect = "AuthenticatedRedirect",
    CallbackOAuth = "CallbackOAuth",
    CallWallboard = "CallWallboard",
    CreateEmail = "CreateEmail",
    Chat = "Chat",
    DeferredQueries = "DeferredQueries",
    LogicFlows = "LogicFlows",
    LogicFlow = "LogicFlow",
    Login = "Login",
    Main = "Main",
    Email = "Email",
    QueryHistory = "QueryHistory",
    Permissions = "Permissions",
    ProactiveChat = "ProactiveChat",
    Reporting = "Reporting",
    ReportingAgents = "ReportingAgents",
    ReportingChat = "ReportingChat",
    ReportingHistoric = "ReportingHistoric",
    ReportingQueue = "ReportingQueue",
    ReportingPublicChat = "ReportingPublicChat",
    ReportingPublicEmail = "ReportingPublicEmail",
    ReportingReports = "ReportingReports",
    ReportingReportsNew = "ReportingReportsNew",
    ReportingReportsEdit = "ReportingReportsEdit",
    ReportingReportsCopy = "ReportingReportsCopy",
    ReportingReportsDisplay = "ReportingReportsDisplay",
    Search = "Search",
    SearchQueries = "SearchQueries",
    SearchInteractions = "SearchInteractions",
    Settings = "Settings",
    SettingsBans = "SettingsBans",
    SettingsBrands = "SettingsBrands",
    SettingsChatWidgetAppearances = "SettingsChatWidgetAppearances",
    SettingsDepartments = "SettingsDepartments",
    SettingsEmailAccounts = "SettingsEmailAccounts",
    SettingsEmailRoutingRules = "SettingsEmailRoutingRules",
    SettingsForms = "SettingsForms",
    SettingsFormsEdit = "SettingsFormsEdit",
    SettingsLanguages = "SettingsLanguages",
    SettingsSetups = "SettingsSetups",
    SettingsSkills = "SettingsSkills",
    SettingsSystem = "SettingsSystem",
    SettingsThemes = "SettingsThemes",
    SettingsUserStates = "SettingsUserStates",
    SettingsTextLocalisations = "SettingsTextLocalisations",
    SettingsTextLocalisationsEdit = "SettingsTextLocalisationsEdit",
    Templates = "Templates",
    Users = "Users",
    NoMatch = "NoMatch",
}

export interface IIndexRouteConfig extends IndexRouteObject {
    path?: RouteObject["path"];
    index: true;
    lazy?: LazyRouteFunction<any>;
    loader?: RouteObject["loader"];
    handle?: unknown; // TODO: find correct type for this.
    initialProperties?: Record<string, any>;
    isRoute?: boolean;
    children?: undefined;
    permission?: unknown; // TODO: find correct type for this.
}

export interface INonIndexRouteConfig extends Omit<NonIndexRouteObject, "children"> {
    index?: false;
    path?: RouteObject["path"];
    lazy?: LazyRouteFunction<any>;
    loader?: RouteObject["loader"];
    handle?: unknown; // TODO: find correct type for this.
    initialProperties?: Record<string, any>;
    isRoute?: boolean;
    children?: IRouteHierarchy;
    permission?: unknown; // TODO: find correct type for this.
}

export type IRouteConfig = IIndexRouteConfig | INonIndexRouteConfig;

export type IRouteHierarchy = {
    [routeName in PageNames]?: IRouteConfig;
} & { [keys: string]: IRouteConfig };
