import React from "react";
import { Field, FieldProps } from "formik";
import { createFieldErrorId } from "@edgetier/utilities";

import UniqueId from "../unique-id";

import CheckedIcon from "./checked-icon";
import UncheckedIcon from "./unchecked-icon";
import { IProps } from "./checkbox.types";

/**
 * Render a checkbox with custom icons that uses Formik.
 * @param props.autoFocus      Automatically focus on the field when it renders.
 * @param props.checked        Optional override for checked value.
 * @param props.isDisabled     Optionally disable the input.
 * @param props.label          Checkbox label.
 * @param props.name           Field name.
 * @param props.onChange       Optional change handler.
 * @param props.transformValue Optional method to change the value stored.
 * @returns                    Checkbox input.
 */
const Checkbox = <T extends {}>({
    autoFocus,
    checked,
    isDisabled,
    label,
    name,
    onChange,
    transformValue,
    ...inputProps
}: IProps<T>) => (
    <UniqueId>
        {(uniqueId) => (
            <Field name={name}>
                {({ field, form }: FieldProps) => {
                    const isChecked = typeof checked === "boolean" ? checked : field.value;
                    return (
                        <div className="radio">
                            <input
                                {...inputProps}
                                aria-describedby={createFieldErrorId(name)}
                                aria-invalid={typeof form.errors[name] === "string"}
                                autoFocus={autoFocus}
                                checked={isChecked}
                                disabled={isDisabled}
                                id={uniqueId}
                                name={name}
                                onChange={(changeEvent) => {
                                    onChange(changeEvent.target.checked);
                                    form.setFieldValue(field.name, transformValue(changeEvent.target.checked));
                                }}
                                type="checkbox"
                            />
                            <label htmlFor={uniqueId}>
                                {isChecked ? <CheckedIcon /> : <UncheckedIcon />}
                                <span className="radio__label-text">{label}</span>
                            </label>
                        </div>
                    );
                }}
            </Field>
        )}
    </UniqueId>
);

Checkbox.defaultProps = {
    checked: undefined,
    isDisabled: false,
    onChange: () => void 0,
    transformValue: (checked: boolean) => checked,
};

export default Checkbox;
